<template>
  <div>
    <div class="page">
      <!-- <h1>Contact Page</h1>
      <p>Contact us here.</p> -->
      <!-- <router-link to="/">Go to HomePage</router-link> -->
    </div></div>
  </template>
  
  <style scoped>
  .page {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 20px;
    background-image: url('/public/image5.png'); 
    background-size: cover; 
    background-position: center;
    background-repeat: no-repeat; 
  }
  </style>