
<template>
    <div class="image-detail" :style="{ 'background-image': `url(${backgroundImage})` }">
      <img :src="imageSrc" alt="Detail Image" class="detail-image" @click="goToModelIntro" title="点击返回">
     
      <!-- 额外的跳转按钮，仅在需要时显示 -->
      <button class="button-pretty"  v-if="showExtraButton" @click="changeBackground">翻页</button>
    </div>
  </template>
  
  <script>
export default {
  data() {
    return {
      showExtraButton: false,
      backgrounds: {
        '/logo1.png': '/detail1.png',
        '/logo2.png': ['/detail2-1.png', '/detail2-2.png'],
        '/logo3.png': '/detail3.png',
        '/logo4.png': '/detail4.png',
      },
      currentBackgroundIndex: 0,
    };
  },
  computed: {
    backgroundImage() {
      const image = this.$route.params.image;
      if (Array.isArray(this.backgrounds[image])) {
        return this.backgrounds[image][this.currentBackgroundIndex] || '/default-background.jpg';
      }
      return this.backgrounds[image] || '/default-background.jpg';
    },
    imageSrc() {
      return this.$route.params.image;
    },
  },
  watch: {
    '$route'(to, from) {
      const image = to.params.image;
      console.log('Route param image:', image); // 调试
      this.showExtraButton = image === '/logo2.png'; // 确保路径一致
    },
  },
  created() {
    const image = this.$route.params.image;
    this.showExtraButton = image === '/logo2.png'; // 初始检查
  },
  methods: {
    goToModelIntro() {
      this.$router.push('/modelintro');
    },
    changeBackground() {
      const image = this.$route.params.image;
      if (Array.isArray(this.backgrounds[image])) {
        this.currentBackgroundIndex = (this.currentBackgroundIndex + 1) % this.backgrounds[image].length;
      }
    },
  },
};

  </script>
  <style scoped>
  .tooltip {
  position: absolute;
  top: 10px; /* 根据需要调整位置 */
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.7); /* 半透明背景 */
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 14px;
  z-index: 10; /* 确保提示在图片上方 */
}
  .image-detail {
    position: relative;
    width: 100%; /* 或者你希望的宽度 */
    height: 100%; /* 或者你希望的高度 */
    background-size: cover; /* 背景图片覆盖整个容器 */
    background-position: center; /* 背景图片居中显示 */
  }
  
  .detail-image {
    cursor: pointer;
    position: absolute;
    bottom: 15%; /* 距离底部10px */
    right: 10%; /* 距离右边10px */
    width: 300px; /* 图片宽度，可以根据需要调整 */
    height: auto; /* 高度自动，保持图片比例 */
    max-width: 300px; /* 最大宽度不超过容器的50%，可以根据需要调整 */
    animation: pulse 2s infinite;
  }
  @keyframes pulse {
  0%, 100% {
    transform: scale(1); /* 初始大小 */
  }
  50% {
    transform: scale(1.12); /* 放大10% */
  }
}
/* 按钮的基本样式 */
.button-pretty {
  padding: 15px 35px;
  border: none;
  border-radius: 10px; /* 更圆润的圆角 */
  background-image: linear-gradient(45deg, #6283e6, #472f69); /* 渐变背景 */
  color: white;
  font-size: 16px;
  font-weight: bold; /* 加粗字体 */
  cursor: pointer;
  transition: transform 0.2s, box-shadow 0.2s; /* 添加阴影和变换的过渡效果 */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* 添加阴影 */
  outline: none;
  position: fixed;
  bottom: 80px;
  left: 110px; /* 修改为右边20px */
  z-index: 1000;
  backdrop-filter: blur(10px); /* 添加背景模糊效果 */
  border: 1px solid rgba(255, 255, 255, 0.5); /* 添加边框 */
}

/* 鼠标悬停时的样式 */
.button-pretty:hover {
  background-image: linear-gradient(135deg, #754caf, #472f69); /* 改变渐变方向 */
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3); /* 增加阴影大小 */
  transform: translateY(-2px); /* 向上移动2px */
}

/* 点击时的样式 */
.button-pretty:active {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* 减少阴影大小 */
  transform: translateY(2px); /* 向下移动2px */
}
  </style>