<template>
  <div id="app" class="pages" @wheel="handleWheel">
    <router-view v-slot="{ Component }">
      <transition :name="transitionName" mode="out-in">
        <component :is="Component" />
      </transition>
    </router-view>
  </div>
</template>

<script setup>
import { ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';

const route = useRoute();
const router = useRouter();
const transitionName = ref('slide');  // 初始过渡名称
const canScroll = ref(true);  // 控制滚动的标志位

// 需要响应滚动的路由路径
const allowedRoutes = [
  '/',        // Home
  // '/about',   // About
  '/services', // Services
  '/contact'  // Contact
];

const handleWheel = (event) => {
  // 如果当前路由不在允许滚动的路由列表中，则不响应滚动事件
  if (!allowedRoutes.includes(route.path)) {
    return;  // 不做任何操作，允许页面滚动
  }

  // 过滤非deltaY的滚动事件和不允许滚动的情况
  if (!event.deltaY || !canScroll.value) return;

  const direction = event.deltaY > 0 ? 'down' : 'up';  // 滚动方向判断
  const routes = [
    { path: '/', name: 'Home' },
    // { path: '/about', name: 'About' },
    { path: '/services', name: 'Services' },
    { path: '/contact', name: 'Contact' }
  ];

  const currentRouteIndex = routes.findIndex((r) => r.path === route.path);
  let nextRouteIndex = currentRouteIndex;

  // 根据滚动方向调整路由索引
  if (direction === 'down' && currentRouteIndex < routes.length - 1) {
    nextRouteIndex += 1;
  } else if (direction === 'up' && currentRouteIndex > 0) {
    nextRouteIndex -= 1;
  }

  // 如果路由发生变化，进行跳转
  if (nextRouteIndex !== currentRouteIndex) {
    router.push(routes[nextRouteIndex].path).catch(() => {});
    event.preventDefault();  // 阻止默认滚动行为
  }

  // 滚动后禁用滚动
  canScroll.value = false;

  // 1秒后允许滚动
  setTimeout(() => {
    canScroll.value = true;
  }, 1000);
};

watch(() => route.path, (newPath, oldPath) => {
  const indexMap = {
    '/': 0,
    // '/about': 1,
    '/services': 1,
    '/contact': 2
  };

  const oldIndex = indexMap[oldPath];
  const newIndex = indexMap[newPath];

  // 根据页面的切换方向设置过渡效果
  if (oldIndex < newIndex) {
    transitionName.value = 'slide-up';  // 向上滑动
  } else {
    transitionName.value = 'slide-down';  // 向下滑动
  }
});
</script>



<style>
.pages {
  position: relative;
  height: 100vh;
  overflow: hidden;
}
.slide-up-enter-active, .slide-up-leave-active,
.slide-down-enter-active, .slide-down-leave-active {
  transition: opacity 0.5s, transform 0.5s;
  transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1); /* 添加缓动函数 */
  overflow: hidden; 
}

.slide-up-enter-from, .slide-down-leave-to {
  opacity: 0;
  transform: translateY(100%) scale(0.95); 
}

.slide-down-enter-from, .slide-up-leave-to {
  opacity: 0;
  transform: translateY(-100%) scale(0.95);
}
html, body, .page {
  height: 100%;
  margin: 0;
  padding: 0;
}
</style>