import { createApp } from 'vue';
import { createRouter, createWebHistory } from 'vue-router';
import App from './App.vue';
import HomeView from './views/HomeView.vue';
// import AboutView from './views/AboutView.vue';
import ContactView from './views/ContactView.vue';
import ServicesView from './views/ServicesView.vue';
import SignUpView from './views/components/SignUp.vue';
import DetailView from './views/components/DetailView.vue';
import ModelView from './views/components/ModelIntro.vue';
import ReportView from './views/components/ReportView.vue';
const routes = [
  { path: '/', component: HomeView },
  { path: '/modelintro/:image', component: DetailView ,name: 'ImageDetail' },
  // { path: '/about', component: AboutView },
  { path: '/services', component: ServicesView },
  { path: '/contact', component: ContactView },
  { path: '/signup', component:SignUpView},
  { path: '/modelintro', component:ModelView},
  { path: '/report', component:ReportView}
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

createApp(App)
  .use(router)
  .mount('#app');