<template>
  <div class="page">
    <div class="header">
      <div class="button-container">
        <img src="../../public/signin.png" alt="Login" @click="handleLoginClick" style="cursor: pointer;"/>
        <img src="../../public/signup.png" alt="Register" @click="handleRegisterClick" style="cursor: pointer;"/>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useRouter } from 'vue-router';
  
const router = useRouter();
  
function handleLoginClick() {
  window.open('https://www.sizhiyuqing.cn/', '_blank');
}
  
function handleRegisterClick() {
  router.push('/signup');
}
</script>

<style scoped>
.page {
  height: 100vh; /* 使用视口高度 */
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 20px;
  background-image: url('/public/image1.png'); 
  background-size: cover; 
  background-position: center;
  background-repeat: no-repeat; 
}

.header {
  background-color: transparent;
  padding: 10px 20px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%; /* 使用百分比宽度 */
  position: absolute;
  top: 10px; /* 根据需要调整 */
  right: 10px;
}

.button-container {
  display: flex;
  gap: 10px; /* 按钮之间的间距 */
}

/* 添加媒体查询以适应不同屏幕尺寸 */
@media (max-width: 768px) {
  .header {
    justify-content: center;
    gap: 20px; /* 在小屏幕上增加按钮间距 */
  }
}

@media (max-width: 480px) {
  .button-container img {
    width: 100%; /* 在小屏幕上使按钮宽度为100% */
    height: auto;
  }
}
</style>