<template>
    <div class="su_body">
      <div class="su_main">
        <div class="textbox">
          <h2>请在下方表单中填写您的个人信息，账号将在审核后被发送至您的手机和邮箱！</h2>
        </div>
        <!-- <div class="registration-form"> -->
          <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSd5Xg_qISnNsliFKQwUbFshut50kUOptVFs4MaHy3A9sSqocQ/viewform?embedded=true" width="680" height="520" frameborder="0" marginheight="0" marginwidth="0">載入中…</iframe>
          <p>若填写异常，请访问：<a href="https://docs.google.com/forms/d/e/1FAIpQLSd5Xg_qISnNsliFKQwUbFshut50kUOptVFs4MaHy3A9sSqocQ/viewform?usp=sf_link">点击跳转Google Form</a></p>
          <button @click="closeThankYou" class="close">返回首页</button>
          <!-- <div v-if="isThankYouVisible" class="thank-you-modal">
            <div class="thank-you-message">
              <h3>感谢您的申请！</h3>
              <p>请耐心等待审核与账号分配。</p>
               -->
            <!-- </div>
          </div> -->
        </div>
      </div>
  </template>
  

  <script setup>
  // import { ref } from 'vue';
  import { useRouter } from 'vue-router';

const router = useRouter(); 

  
  const closeThankYou = () => {
    router.push('/#/');
  };
  </script>
  
  
<style scope>
.su_body {
    /* font-family: Arial, sans-serif; */
    background: linear-gradient(to bottom, #f4f4f4, #d8f7ff);
    color: #333;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    margin: 0;
}
.h1{
    font-size: 12px;
}
.su_main {
  display: flex; /* 启用Flex布局 */
  flex-direction: column;
    align-items: center; /* 垂直居中 */
    margin-top: 5%;
    margin-bottom: 25%;
    align-items: center;
    max-width: 800px;
    padding: 20px;
    background-color: #fff;
    border-radius: 15px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
.registration-form {
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
  background: white;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin-bottom: 10px;
  align-items: center;
}

.form-group {
  margin-bottom: 15px;
  margin-right: 5%;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
}
.textbox{
    font-size: 12px;
    margin-inline: 40px;
    /* max-width: 300px; */
}
.form-group input,
.form-group textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}
.submit-button {
    
    padding: 10px 35px; 
  background-image: linear-gradient(45deg, #53cbff , #8941fe ); 
  text-decoration: none;
  border:solid #0059ffe0 1px; 
  border-radius: 20px; 
  cursor: pointer; 
  font-size: 16px; 
  font-weight: 800; 
  text-align: center;
  color: #fff; 
  text-transform: uppercase; 
  letter-spacing: 0.5px; 
  box-shadow: 0 8px 15px rgba(255, 255, 255, 0.497);
  transition: all 0.4s ease; 
  outline: none; 
  position: relative;
  overflow: hidden; 
  font-family: 'Open Sans', sans-serif;
  margin-left: 35%;
  /* margin-right: auto; */
}

.submit-button:hover ::before{
    opacity: 1;
}
.submit-button:hover {
    background-image: linear-gradient(-45deg, #8941fe,#53cbff  ); 
  transform: translateY(-10px);
  box-shadow: 0 10px 20px rgba(255, 255, 255, 0.764);
}

.submit-button:active {
  transform: translateY(0); 
  box-shadow: 10 18px 25px rgba(255, 255, 255, 0.921); 
}

.thank-you-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.thank-you-message {
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
  width:300px;
}
.close{
    padding: 10px 20px; 
  background-image: linear-gradient(45deg, #53cbff , #8941fe ); 
  text-decoration: none;
  border:solid #0059ffe0 1px; 
  border-radius: 20px; 
  cursor: pointer; 
  font-size: 16px; 
  font-weight: 800; 
  text-align: center;
  color: #fff; 
  text-transform: uppercase; 
  letter-spacing: 0.5px; 
  box-shadow: 0 8px 15px rgba(255, 255, 255, 0.497);
  transition: all 0.4s ease; 
  outline: none; 
  position: relative;
  overflow: hidden; 
  font-family: 'Open Sans', sans-serif;

}
</style>