<template>
  <div class="paaaa">
    <div class="left">
      <img src="/service_logo.png">
    </div>
    <div class="right">
      <img
        v-for="(image, index) in images"
        :key="index"
        :src="image"
        :alt="`Logo ${index + 1}`"
        title="点击查看"
        @click="openImage(image)"
      />
    </div>
    <button class="backtohome" @click="backtohome">返回</button>
  </div>
</template>

<script>
export default {
  data() {
    return {
      images: [
        '/logo1.png',
        '/logo2.png',
        '/logo3.png',
        '/logo4.png',
      ],
    };
  },
  methods: {
    openImage(image) {
      this.$router.push({ name: 'ImageDetail', params: { image } });
    },
    backtohome() {
      this.$router.push('/services');
    }
  },
};
</script>

<style>
.backtohome {
  padding: 15px 35px;
  border: none;
  border-radius: 10px;
  background-image: linear-gradient(45deg, #6283e6, #472f69);
  color: white;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  transition: transform 0.2s, box-shadow 0.2s;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  outline: none;
  position: fixed;
  top: 80px;
  right: 100px;
  z-index: 1000;
  backdrop-filter: blur(10px);
  border: 2px solid rgba(255, 255, 255, 0.5);
}

.backtohome:hover {
  background-image: linear-gradient(135deg, #754caf, #472f69);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
  transform: translateY(-2px);
}

.backtohome:active {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  transform: translateY(2px);
}

.paaaa {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 20px;
  background-image: url('/public/service1.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  overflow: auto;
}

.left {
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  height: 100%;
  overflow: hidden;
}

.left img {
  max-width: 80%;
  max-height: 70%;
  object-fit: contain;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  animation: breathe 3s ease-in-out infinite;
}

@keyframes breathe {
  0%, 100% {
    transform: translate(-50%, -50%) scale(1);
  }
  50% {
    transform: translate(-50%, -50%) scale(1.05);
  }
}

.right {
  width: 53%;
  max-height: 60%;
  position: absolute;
  right: 0;
  top: 30%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  height: 100%;
  padding: 60px;
  box-sizing: border-box;
}

.right img {
  height: auto;
  max-width: 270px;
  object-fit: contain;
  margin-left: 10px;
  transition: opacity 0.3s ease;
  cursor: pointer;
}

.right img:hover {
  opacity: 0.3;
  transform: translateY(-15px);
  position: relative;
}

.right img:hover::after {
  content: url('../../../public/finger_icon.png');
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 1;
}

@media (max-width: 1200px) {
  .right {
    width: 60%;
  }
}

@media (max-width: 992px) {
  .right {
    width: 70%;
  }
}

@media (max-width: 768px) {
  .right {
    width: 80%;
  }
}

@media (max-width: 576px) {
  .right {
    width: 90%;
  }
}
</style>